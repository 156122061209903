import styled, { css } from "styled-components";

type Headings = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface StyledButtonProps {
  size?: Headings;
  $uppercase?: boolean;
}

export const StyledHeading = styled.h2<StyledButtonProps>`
  display: block;
  font-family: ${({ theme }) => theme.fonts.abcWalterNeue};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacings.default};
  line-height: 1;
  margin: 0;
  overflow: hidden;
  position: relative;

  ${({ size, theme }) =>
    size === "h1" &&
    css`
      font-size: ${theme.fontSizes[80]};
      line-height: ${theme.lineHeights[81]};

      ${theme.breakpoints.up("desktop")} {
        font-size: ${theme.fontSizes[96]};
      }
    `}

  ${({ size, theme }) =>
    size === "h2" &&
    css`
      font-size: ${theme.fontSizes[48]};

      ${theme.breakpoints.up("desktop")} {
        font-size: ${theme.fontSizes[60]};
        line-height: ${theme.lineHeights[56]};
      }
      ${theme.breakpoints.down("desktop")} {
        font-size: ${theme.fontSizes[32]};
        line-height: ${theme.lineHeights[24]};
      }
    `}

  ${({ size, theme }) =>
    size === "h3" &&
    css`
      font-size: ${theme.fontSizes[32]};

      ${theme.breakpoints.up("desktop")} {
        font-size: ${theme.fontSizes[48]};
      }
    `}

  ${({ size, theme }) =>
    size === "h4" &&
    css`
      font-size: ${theme.fontSizes[24]};

      ${theme.breakpoints.up("desktop")} {
        font-size: ${theme.fontSizes[36]};
        line-height: ${theme.lineHeights[32]};
      }
    `}

  ${({ size, theme }) =>
    size === "h5" &&
    css`
      font-size: ${theme.fontSizes[16]};

      ${theme.breakpoints.up("desktop")} {
        font-size: ${theme.fontSizes[24]};
        line-height: ${theme.lineHeights[32]};
      }
    `}

  ${({ size, theme }) =>
    size === "h6" &&
    css`
      font-size: ${theme.fontSizes[12]};

      ${theme.breakpoints.up("desktop")} {
        font-size: ${theme.fontSizes[16]};
        line-height: ${theme.lineHeights[20]};
      }
    `}

  ${({ $uppercase }) =>
    $uppercase &&
    css`
      text-transform: uppercase;
    `}
`;

export const AnimatedText = styled.span`
  left: 0;
  position: absolute;
  top: 0;
`;
export const CenteredAnimatedText = styled.span`
  left: 0;
  top: 0;
`;

interface OriginalTextProps {
  $isHidden: boolean;
}

export const OriginalText = styled.span<OriginalTextProps>`
  ${({ $isHidden }) =>
    $isHidden &&
    css`
      visibility: hidden;
    `}
`;
