import {
  ComponentProps,
  ElementType,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AnimatedText,
  CenteredAnimatedText,
  OriginalText,
  StyledHeading,
} from "./Heading.styles";
import { ScrambleOptions, useScramble } from "~/hooks/useScramble";
import { useIntersection } from "react-use";

export interface HeadingProps extends ComponentProps<typeof StyledHeading> {
  as?: ElementType;
  children: string;
  hasMotion?: boolean;
  scrambleOptions?: Omit<ScrambleOptions, "paused">;
  uppercase?: boolean;
  centered?: boolean;
}

const getCharacters = () => {
  const characters = [];

  for (let i = 0; i < 26; i += 1) {
    // Characters a-z
    characters.push(String.fromCharCode(97 + i));
  }

  characters.push("/", ".", ":", "-", "_", "{", "}", "\\", "[", "]", ">");

  return characters;
};

const Heading = ({
  children,
  hasMotion = true,
  scrambleOptions = {},
  uppercase = true,
  centered,
  ...props
}: HeadingProps) => {
  const [isPaused, setIsPaused] = useState(true);
  const intersectionRef = useRef<HTMLElement | null>(null);

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const text = useScramble(children, {
    ...scrambleOptions,
    characters: scrambleOptions.characters || getCharacters(),
    paused: isPaused,
  });

  useEffect(() => {
    if (hasMotion && intersection?.intersectionRatio === 1) {
      setIsPaused(false);
    }
  }, [hasMotion, intersection?.intersectionRatio]);

  return (
    <StyledHeading
      {...props}
      ref={intersectionRef}
      $uppercase={uppercase}
      onMouseEnter={() => setIsPaused(false)}
      onMouseLeave={() => setIsPaused(true)}
    >
      {hasMotion &&
        (centered ? (
          <CenteredAnimatedText>{text}</CenteredAnimatedText>
        ) : (
          <AnimatedText>{text}</AnimatedText>
        ))}
      {!centered ? (
        <OriginalText $isHidden={hasMotion}>{children}</OriginalText>
      ) : null}
    </StyledHeading>
  );
};

export default Heading;
